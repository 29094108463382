<!-- System: STA
    Purpose: This compoment will display Timesheet
            reports section.
-->
<template>
  <div>
    <v-row @click="menu = false" class="mt-5 d-flex justify-end">
      <!-- Date Range Picker -->
      <v-col cols="12" sm="12" md="2" class="mt-n1">
        <label
          class="labels ml-4 d-block font-weight-thin text-left"
          for
          id="cRangee"
          >Date Range:</label
        >
        <v-menu
          id="list-menu-new"
          :close-on-click="false"
          nudge-left="0"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-col class="d-flex flex-row-reverse">
              <v-subheader
                class="selected-date"
                v-bind="attrs"
                v-on="on"
                style="width: 100%"
                @click="checkClick"
              >
                {{ dateRangeTitleNew }}
                <span class="d-flex justify-start ml-auto">
                  <v-icon
                    color="primary"
                    id="rangeBtnNew"
                    class="mdi-dark mdi-inactive"
                    >mdi-calendar-range</v-icon
                  >
                </span>
              </v-subheader>
            </v-col>
          </template>
          <v-list id="custom-list-style-new">
            <v-list-item
              style="min-height: 30px"
              v-for="(item, index) in items"
              :key="index"
              @click="checkIfCustomDate(item)"
            >
              <v-list-item-title style="font-size: 13px" class="custom">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-dialog
          ref="dialognew"
          v-model="modal_23"
          :return-value.sync="date"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-show="(modal_23_show = false)"
              v-model="date"
              label="Picker in dialog"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" range no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeDateModal()">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              id="apply-btn"
              @click="gettimeFromCustomDate(), selectedDateTeamMembers()"
            >
              Apply
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <!-- Group By Dropdown -->
      <v-col cols="12" sm="12" md="2">
        <v-autocomplete
          outlined
          dense
          class="mt-3"
          v-model="sheetGroupBy"
          :items="groupByFilter"
          item-text="value"
          item-value="id"
          label="Group By"
        ></v-autocomplete>
      </v-col>
      <!-- Members Dropdown -->
      <v-col cols="12" sm="12" md="2">
        <v-autocomplete
          outlined
          dense
          class="mt-3"
          v-model="selectedProject"
          :items="sortProjects"
          item-text="project_name"
          item-value="id"
          label="Search By Project"
        ></v-autocomplete>
      </v-col>
      <!-- Member Dropdown -->
      <v-col cols="12" sm="12" md="2">
        <v-autocomplete
          outlined
          dense
          class="mt-3"
          v-model="selectedMember"
          :items="teamMember"
          :loading="teamMemberLoader"
          item-text="name"
          item-value="id"
          label="Search By Members"
        ></v-autocomplete>
      </v-col>
      <!-- Export Button -->
      <v-col cols="12" sm="12" md="2">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#2758f6"
              class="mt-3 px-16 px-md-5 px-lg-16"
              dark
              v-bind="attrs"
              v-on="on"
              offset-y
            >
              Export As
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="exportToCSV('timesheetTable')">
              <v-list-item-title align="left"> CSV </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="exportToExcel('timesheetTable')">
              <v-list-item-title align="left"> Excel </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <!-- Search Button -->
      <v-col cols="12" sm="12" md="2">
        <v-btn
          primary
          class="active-custom-btn mt-3 px-16 px-md-5 px-lg-16"
          @click="getTimesheet"
        >
          <span class="mdi">Search</span>
        </v-btn>
      </v-col>
      <!-- Render If No Data Is Available -->
      <v-col
        cols="12"
        sm="12"
        md="12"
        class="text-center"
        v-if="!timesheetData || timesheetData.length === 0"
      >
        <img
          class="error-img"
          src="../../assets/images/no-activity-found-new.svg"
        />
        <p class="error_text" v-if="toggleLoader === false">Loading...</p>
        <p class="error_text" v-else>No data found</p>
      </v-col>
      <!-- Timesheet Table -->
      <v-col cols="12" sm="12" md="12" class="zui-wrapper" v-else>
        <div class="table-div">
          <table class="timesheet-table" id="timesheetTable">
            <thead>
              <tr>
                <th
                  data-fill-color="#2658f6"
                  data-f-color="#FFFFFF"
                  class="table-sticky-col-name custom-padding"
                  style="cursor: pointer"
                  @click="sortData('name')"
                >
                  Employee Name<img class="sort_icon" /><span
                    class="sort"
                    @click="sortData('name')"
                    ><i class="fa fa-sort" aria-hidden="true"></i
                  ></span>
                </th>
                <th
                  data-fill-color="#2658f6"
                  data-f-color="#FFFFFF"
                  class="table-sticky-col-designation custom-padding"
                  style="cursor: pointer"
                  @click="sortData('department')"
                >
                  Department <img class="sort_icon" /><span
                    class="sort"
                    @click="sortData('department')"
                    ><i class="fa fa-sort" aria-hidden="true"></i
                  ></span>
                </th>
                <th
                  data-fill-color="#2658f6"
                  data-f-color="#FFFFFF"
                  class="table-sticky-col-designation custom-padding"
                  style="cursor: pointer"
                  @click="sortData('designation')"
                >
                  Designation <img class="sort_icon" /><span
                    class="sort"
                    @click="sortData('designation')"
                    ><i class="fa fa-sort" aria-hidden="true"></i
                  ></span>
                </th>
                 <th
                  data-fill-color="#2658f6"
                  data-f-color="#FFFFFF"
                  class="table-sticky-col-totaltime custom-padding"
                  style="cursor: pointer"
                  @click="sortData('percentage')"
                >
                  Total Progress
                  <span class="sort"
                    ><i class="fa fa-sort" aria-hidden="true"></i
                  ></span>
                </th>
                <th
                  data-fill-color="#2658f6"
                  data-f-color="#FFFFFF"
                  class="table-sticky-col-totaltime custom-padding"
                  style="cursor: pointer"
                  @click="sortData('hours')"
                >
                  {{ timesheetType === "time" ? "Total Time" : "Progress" }}
                  <span class="sort"
                    ><i class="fa fa-sort" aria-hidden="true"></i
                  ></span>
                </th>
                <th
                  class="text-center scroll-column"
                  v-for="(date, index) in dates"
                  :key="index"
                  data-f-color="#FFFFFF"
                  :data-fill-color="
                    formatDate(date) === 'Sun' || formatDate(date) === 'Sat'
                      ? '#ff7043'
                      : '#2658f6'
                  "
                  :class="
                    formatDate(date) === 'Sun' || formatDate(date) === 'Sat'
                      ? 'custom-column-color'
                      : ''
                  "
                >
                  <span>{{ date }}</span>
                  <br />
                  <small>({{ formatDate(date) }})</small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="total-row">
                <td
                  data-fill-color="#cbdaf2"
                  data-f-color="#0800FF"
                  class="table-sticky-col-totaltimeRow total-col"
                >
                  Grand Total
                  {{ timesheetType === "time" ? "Time" : "Progress" }}:
                </td>
                <td data-fill-color="#cbdaf2" class="extra-td-1"></td>
                <td data-fill-color="#cbdaf2" class="extra-td-1"></td>
                <td data-fill-color="#cbdaf2" class="extra-td-1"></td>
                <td data-fill-color="#cbdaf2" class="extra-td-2"></td>
                <td
                  data-fill-color="#cbdaf2"
                  data-f-color="#0800FF"
                  v-for="(date, g) in dates"
                  class="text-center total-col"
                  :key="g"
                  @mouseleave="leaveSpan"
                  @mouseover="toggleSpanGrand(g, 'grand')"
                >
                  <span v-if="timesheetType == 'time'">
                    {{
                      hoveredCell == "grand-time" + g
                        ? getGrandTotal("progress", date)
                        : getGrandTotal("time", date)
                    }}
                  </span>
                  <span v-else>
                    {{
                      hoveredCell == "grand-progress" + g
                        ? getGrandTotal("time", date)
                        : getGrandTotal("progress", date)
                    }}
                  </span>
                </td>
              </tr>
              <tr v-for="(data, j) in timesheetData" :key="j">
                <v-tooltip :id="'span-'+j" class="uner_info_tooltip" top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <td    
                   :data-fill-color="data.grouped_row ? '#2658f6' : '#F1F1F1'"
                   :data-f-color="data.grouped_row ? '#FFFFFF' : ''"
                      class="table-sticky-col-name text-left"
                      :title="data.name || data.title"
                      :class="{ 'grouped-row': data.grouped_row }"
                    >
                      <img
                        v-if="exportImage && data.name && !displayTempImg"
                        :src="`${url}storage/${userData(data.user_hash_id)}`"
                        @error="
                          $event.target.src = require('../../assets/images/no-member.svg')
                        "
                        alt="Thumbnail"
                        class="timesheet-image circle"
                        :id="'popover' + data.user_hash_id"
                        v-bind="attrs"
                        v-on="on"
                      />
                      <img
                        v-if="data.grouped_row && displayTempImg"
                        src="../../assets/images/no-member.svg"
                        @error="
                          $event.target.src = require('../../assets/images/no-member.svg')
                        "
                        alt="no member"
                        class="timesheet-image circle"
                        v-bind="attrs"
                        v-on="on"
                      />

                      {{ trimData(data.name)  || data.title.slice(0, 15)   }}
                    </td>
                  </template>
                     <UserInfo
                    :member="mouseOverUserInfo(data.user_hash_id)"
                  ></UserInfo>
                </v-tooltip>
                 <td
                  v-if="!data.grouped_row"
                  data-fill-color="#F1F1F1"
                  class="table-sticky-col-designation"
                  :title="data.department"
                  :class="{ 'grouped-row': data.grouped_row }"
                >
                  <span v-if="data.department && !data.grouped_row">
                    {{ trimData(data.department) }}
                  </span>
                </td>
                <td
                v-if="data.grouped_row"
                   data-fill-color="#2658f6"
                   data-f-color="#FFFFFF"
                  class="table-sticky-col-designation"
                  :title="data.department"
                  :class="{ 'grouped-row': data.grouped_row }"
                >
                  <span v-if="data.department && !data.grouped_row">
                    {{ trimData(data.department) }}
                  </span>
                </td>
                 <td
                  v-if="data.grouped_row"
                   data-fill-color="#2658f6"
                   data-f-color="#FFFFFF"
                  :class="{ 'grouped-row': data.grouped_row }"
                  class="table-sticky-col-designation"
                  :title="data.designation"
                >
                  <span v-if="data.designation && !data.grouped_row">
                    {{ trimData(data.designation)  }}
                  </span>
                </td>
                <td
                v-if="!data.grouped_row"
                  data-fill-color="#F1F1F1"
                  :class="{ 'grouped-row': data.grouped_row }"
                  class="table-sticky-col-designation"
                  :title="data.designation"
                >
                  <span v-if="data.designation && !data.grouped_row">
                    {{ trimData(data.designation)  }}
                  </span>
                </td>
                 <td
                  data-fill-color="#2658f6"
                  data-f-color="#FFFFFF"
                  v-if="data.grouped_row"
                  class="grouped-row text-center"
                  @mouseleave="leaveSpan"
                  @mouseover="toggleSpanGrouped(j, 'grouped')"
                >
                  <span>
                    {{
                     data.grand_total_percentage + "%" 
                    }}
                  </span>
                </td>
                <td
                 data-fill-color="#2658f6"
                 data-f-color="#FFFFFF"
                  v-if="data.grouped_row"
                  class="grouped-row text-center"
                  @mouseleave="leaveSpan"
                  @mouseover="toggleSpanGrouped(j, 'grouped')"
                >
                  <span v-show="timesheetType == 'progress'">
                    {{
                      hoveredCell == "grouped-progress" + j
                        ? data.grand_total_time 
                        : data.grand_total_percentage 
                    }}
                  </span>
                  <span v-show="timesheetType == 'time'">
                    {{
                      hoveredCell == "grouped-time" + j
                        ? data.grand_total_percentage + "%"
                        : data.grand_total_time
                    }}
                  </span>
                </td>
                <td
                 data-fill-color="#2658f6"
                 data-f-color="#FFFFFF"
                  class="grouped-row text-center"
                  v-if="data.grouped_row"
                  v-for="(date, i) in dates"
                  :key="i"
                  @mouseleave="leaveSpan"
                  @mouseover="toggleSpanGrandTotal(data.title, i)"
                >
                  <span v-show="timesheetType == 'time'">
                    {{
                      hoveredCell == data.title + "time" + i
                        ? data.percentage[i][date] + "%" 
                        : data.hours[i][date]
                    }}
                  </span>
                  <span v-show="timesheetType == 'progress'">
                    {{
                      hoveredCell == data.title + "progress" + i
                        ? data.hours[i][date]
                        : data.percentage[i][date] + "%"
                    }}
                  </span>
                </td>
                 <td
                  data-fill-color="#cbdaf2"
                  v-if="!data.grouped_row"
                  class="total-col text-center"
                  @mouseleave="leaveSpan"
                  @mouseover="toggleSpan(j, '0', 'total')"
                >
                  <span v-if="timesheetType == 'time'">
                    {{
                      getTotalPercentage(data.percentage) + "%" 
                    }}
                  </span>
                </td>
                <td
                  data-fill-color="#cbdaf2"
                  v-if="!data.grouped_row"
                  class="total-col text-center"
                  @mouseleave="leaveSpan"
                  @mouseover="toggleSpan(j, '0', 'total')"
                >
                  <span v-if="timesheetType == 'time'">
                    {{
                      hoveredCell == "total-time" + j + "0"
                        ? getTotalPercentage(data.percentage) + "%" 
                        : getTotalTime(data.hours)
                    }}
                  </span>
                  <span v-if="timesheetType == 'progress'">
                    {{
                      hoveredCell == "total-progress" + j + "0"
                        ? getTotalTime(data.hours)
                        : getTotalPercentage(data.percentage) + "%"
                    }}
                  </span>
                </td>
                <td
                  :data-fill-color="
                    getColorCode(data.hours, date) == 1
                      ? '#22a522'
                      : getColorCode(data.hours, date) == 2
                      ? '#7abd7a'
                      : getColorCode(data.hours, date) == 3
                      ? '#b5e0b5'
                      : getColorCode(data.hours, date) == 4
                      ? '#ff7043'
                      : getColorCode(data.hours, date) == 5
                      ? '#e3f1dd'
                      : ''
                  "
                  v-if="!data.grouped_row"
                  v-for="(date, i) in dates"
                  :key="i"
                  @click="
                    getReportData(
                      data.user_hash_id + '-' + i,
                      date,
                      data.user_hash_id,
                      data.name,
                      data.hours
                    )
                  "
                  :class="
                    timesheetType == 'time'
                      ? 'status-' + getColorCode(data.hours, date)
                      : 'status-' + getColorCodeProgress(data.percentage, date)
                  "
                  :id="data.user_hash_id + '-' + i"
                  class="text-center"
                  @mouseleave="
                    data.mouse_keystrokes_clicks > 1 ? leaveSpan : ''
                  "
                  @mouseover="
                    data.mouse_keystrokes_clicks > 1 ? toggleSpan(j, i) : ''
                  "
                >
                  <span v-if="timesheetType == 'time'">
                    {{
                      hoveredCell === "time" + j + i
                        ? JSON.parse(data.percentage)[date]
                          ? JSON.parse(data.percentage)[date] + "%"
                          : "0%"
                        : JSON.parse(data.hours)[date]
                        ? JSON.parse(data.hours)[date]
                        : "0:0"
                    }}
                  </span>
                  <span v-if="timesheetType == 'progress'">
                    {{
                      hoveredCell === "progress" + j + i
                        ? JSON.parse(data.hours)[date]
                          ? JSON.parse(data.hours)[date]
                          : "0:0"
                        : JSON.parse(data.percentage)[date]
                        ? JSON.parse(data.percentage)[date] + "%"
                        : "0%"
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Report Video Component  -->
          <create-video-modal :video_data="videoData"></create-video-modal>
          <!-- Dialog For Intelligence Report -->
          <v-dialog
            transition="dialog-bottom-transition"
            max-width="1400"
            v-model="intellegenceRModal"
          >
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="primary" dark>Intelligence Report</v-toolbar>
                <v-card-text>
                  <IntelligenceReport
                    :dataForIntlReport="dataForIntlReport"
                    v-if="checkIntlReport"
                  />
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="closeModalIntelligenceReport"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <!--  Reports Modal --->
          <v-dialog
            v-model="reportsModal"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="closeModalReport()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Reports</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items
                  v-if="
                    selectedCompanyUserStatus !== 'employee' && showPermission
                  "
                  @click="playVideo()"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark text v-bind="attrs" v-on="on">
                        <v-icon>mdi-video</v-icon>
                      </v-btn>
                    </template>
                    <span>Play Video</span>
                  </v-tooltip>
                </v-toolbar-items>
                <v-toolbar-items
                  v-if="
                    selectedCompanyUserStatus !== 'employee' && showPermission
                  "
                  @click="workDairy()"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark text v-bind="attrs" v-on="on">
                        <v-icon>mdi-briefcase</v-icon>
                      </v-btn>
                    </template>
                    <span>Work Diary</span>
                  </v-tooltip>
                </v-toolbar-items>
                <v-toolbar-items
                  v-if="
                    selectedCompanyUserStatus !== 'employee' && showPermission
                  "
                  @click="intellegentReport()"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark text v-bind="attrs" v-on="on">
                        <v-icon>mdi-briefcase-check</v-icon>
                      </v-btn>
                    </template>
                    <span>Intelligence Report</span>
                  </v-tooltip>
                </v-toolbar-items>
              </v-toolbar>
              <p>
                <!-- Reports Component -->
                <StatsReports
                  :dataForReport="dataForReport"
                  v-if="checkReport"
                />
              </p>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment";
import $ from "jquery";
import { mapState } from "vuex";
import { baseURL } from "@/assets/js/urls";
import UserInfo from "../includes/UserInfo";
import TableToExcel from "@linways/table-to-excel";
export default {
  name: "TimesheetView",
  components: {
    StatsReports: () => import("@/components/reports/report"),
    CreateVideoModal: () => import("@/components/workDiary/CreateVideoModal"),
    IntelligenceReport: () => import("@/components/reports/IntelligentReport"),
    UserInfo,
  },
  data() {
    return {
      intellegenceRModal: false,
      teamMemberLoader: false,
      menu_2: false,
      modal_23: false,
      displayTempImg: true,
      date: [],
      showPicker: false,
      menu: false,
      reportsModal: false,
      items: [
        { id: 2, title: "Yesterday" },
        { id: 3, title: "Last 30 Days", value: "" },
        { id: 4, title: "This Month", value: "" },
        { id: 5, title: "Last Month", value: "" },
        { id: 6, title: "Custom Range" },
      ],
      groupByFilter: [
        { id: "designation", value: "Designation" },
        { id: "department", value: "Department" },
        { id: "leads", value: "Team Leads" },
      ],
      timesheetData: null,
      originalData: null,
      dates: [],
      grandTotal: [],
      timesheetType: "time",
      dateRangeTitleNew: "",
      start: moment().subtract(29, "days"),
      end: moment().subtract(1, "days"),
      start_date: moment().subtract(29, "days").format("YYYY-MM-DD"),
      end_date: moment().subtract(1, "days").format("YYYY-MM-DD"),
      teamMembers: [],
      selectedMember: null,
      sheetGroupBy: "designation",
      hoveredCell: "",
      videoData: "",
      selectedOrder: "desc",
      checkIntlReport: false,
      checkReport: false,
      dataForIntlReport: {
        search_member: null,
        search_date: null,
        search_name: null,
      },
      dataForReport: {
        search_member: null,
        search_date: null,
        search_name: null,
      },
      toggleLoader: false,
      department: "all",
      departmentList: "",
      iconHide: true,
      exportImage: true,
      selectedProject: "all",
    };
  },
  mounted() {
    setTimeout(() => {
      this.displayTempImg = false;
    }, 5000);
    window.addEventListener("click", () => {
      let x = document.getElementById("custom-list-style-new");
      if (x && window.getComputedStyle(x).display != "none") {
        document.getElementById("rangeBtnNew").click();
        x.style.display = "none";
      }
    });
    this.checkIfCustomDate(this.date);
    this.selectedDateTeamMembers();
    this.getTimesheet();
    if (
      !this.$store.state.custom.departments ||
      this.$store.state.custom.departments.length == 0
    ) {
      this.get_department_list();
    } else {
      this.departmentList = this.$store.state.custom.departments;
      this.departmentList = _.orderBy(this.departmentList, ["name"], ["asc"]);
    }
    if (this.$store.state.custom.projects_list.length < 1) {
      this.getProjects();
    }
  },
  watch: {
    sheetGroupBy(value) {
      if (value == "designation") {
        this.timesheetData = _.orderBy(
          this.originalData,
          ["designation"],
          ["asc"]
        );
      }
      if (value == "department") {
        this.timesheetData = _.orderBy(
          this.originalData,
          ["department"],
          ["asc"]
        );
        this.handleGroupBy("department");
      }
      if (value == "leads") {
        this.timesheetData = _.orderBy(this.originalData, ["name"], ["asc"]);
        this.handleGroupBy("leader_id");
      }
    },
  },
  computed: {
    ...mapState("custom", [
      "selected_company",
      "projects_list",
      "companies_list",
    ]),
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    showPermission() {
      let permission = this.$store.state.custom.selected_company.permission;
      if (this.selectedCompanyUserStatus === "secondary_user") {
        if (permission.work_diary) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    /**
     * Return the base url of api
     */
    url() {
      return baseURL.API_URL;
    },
    /**
     * Return the custom list of
     * team members
     */
    teamMember() {
      let newArray = [];
      this.teamMembers.map((member) => {
        newArray.push(member);
      });
      return newArray;
    },
    /**
     * Return the custom list of
     * sorted Project List
     */
    sortProjects() {
      let newProject = [];
      let teamMembersProjectsList = this.projects_list.active;
      let allProject = {
        id: "all",
        project_name: "All Projects",
      };
      newProject.push(allProject);
      teamMembersProjectsList = teamMembersProjectsList
        .sort(function (a, b) {
          if (a.project_name < b.project_name) return -1;
          if (a.project_name > b.project_name) return 1;
          return 0;
        })
        .map((project) => {
          newProject.push(project);
        });
      return newProject;
    },
  },

  methods: {
    /*
      Fetch the lastest working members
      data according to given date
     */
    selectedDateTeamMembers() {
      let data = {
        company_id: this.$route.params.id,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.teamMemberLoader = true;
      this.$store
        .dispatch("custom/getLastWorkingDaysMember", data)
        .then((response) => {
          this.teamMemberLoader = false;
          this.teamMembers = response.data.data.team_members;
          if (this.teamMember[0].id != "all") {
            let allMember = {
              id: "all",
              name: "All Members",
            };
            this.teamMembers.unshift(allMember);
          }
          this.teamMemberLoader = false;
        })
        .catch(() => {
          this.teamMemberLoader = false;
        });
    },
    /**
     * This function is resposible for
     * get projects (api call)
     */
    getProjects() {
      this.$store.dispatch("custom/getProjects", {
        company_id: this.$route.params.id,
      });
    },
    /**
     * This function is resposible for
     * when modal closes
     */
    closeDateModal() {
      this.modal_23 = false;
      this.menu = false;
    },
    checkClick() {
      let x = document.getElementById("custom-list-style-new");
      if (x && window.getComputedStyle(x).display != "block") {
        x.style.display = "block";
      }
    },
    /**
     * This function is resposible for
     * get report w.r.t custom date
     */
    gettimeFromCustomDate() {
      this.modal_23 = false;
      this.start_date = moment(this.date[0]).format("YYYY-MM-DD");
      this.end_date = moment(this.date[1]).format("YYYY-MM-DD");
      if (this.start_date.toString() === "Invalid date") {
        let date = this.end_date.split("-");
        let year = date[0];
        let month = date[1];
        this.start_date = year + "-" + month + "-01";
        this.date[0] = year + "-" + month + "-01";
      }
      this.dateRangeTitleNew =
        moment(this.date[0]).format("ll") +
        " - " +
        moment(this.date[1]).format("ll");
      if (this.start_date > this.end_date) {
        this.checkIfStartDateGreater();
        this.dateRangeTitleNew =
          moment(this.date[1]).format("ll") +
          " - " +
          moment(this.date[0]).format("ll");
      }
      if (this.end_date < this.start_date) {
        this.start_date = this.start_date
          ? this.start_date
          : moment(this.date[1]).format("YYYY-MM-DD");
        this.end_date = moment(this.date[0]).format("YYYY-MM-DD");
      } else if (this.start_date > this.end_date) {
        this.checkIfStartDateGreater();
      }
      // this.getTimesheet(this.date);
      this.date = "";
      this.menu = false;
    },
    checkIfStartDateGreater() {
      let etmp = "";
      let stmp = "";
      stmp = this.end_date;
      etmp = this.start_date;
      this.start_date = stmp;
      this.end_date = etmp;
    },
    /**
     * This function is resposible for
     * custom date range picker
     */
    checkIfCustomDate(date) {
      date.value =
        moment().subtract(29, "days").format("MMM D, YYYY") +
        "-" +
        moment().subtract(1, "days").format("MMM D, YYYY");
      this.dateRangeTitleNew = date.value;
      if (date.id === 1) {
        let start = moment().format("YYYY-MM-DD");
        let end = moment().format("YYYY-MM-DD");
        this.dateRangeTitleNew = "Today";
      }
      if (date.id === 2) {
        let start = moment().subtract(1, "days").format("YYYY-MM-DD");
        let end = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.dateRangeTitleNew = "Yesterday";
      }
      if (date.id === 3) {
        date.value =
          moment().subtract(29, "days").format("MMM D, YYYY") +
          "-" +
          moment().format("MMM D, YYYY");
        this.dateRangeTitleNew = date.value;
      }
      if (date.id === 5) {
        date.value =
          moment().subtract(1, "month").startOf("month").format("MMM D, YYYY") +
          "-" +
          moment().subtract(1, "month").endOf("month").format("MMM D, YYYY");
        this.start_date = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.end_date = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        this.dateRangeTitleNew = date.value;
      }
      if (date.id === 4) {
        date.value =
          moment().startOf("month").format("MMM D, YYYY") +
          "-" +
          moment().endOf("month").format("MMM D, YYYY");
        this.dateRangeTitleNew = date.value;
      }

      if (date.id === 6) {
        this.modal_23 = true;
      } else {
        this.showPicker = false;
        this.setDates(date);
        if (
          this.selectedCompanyUserStatus === "employee" &&
          !this.selectedCompanyUserHasTeam
        ) {
          this.memberChanged(this.start_date, this.end_date);
        }
      }
      this.selectedDateTeamMembers();
      if (!this.reportsModal) document.getElementById("cRangee").click();
    },

    setDates(date) {
      if (date.id === 1) {
        this.start_date = moment().format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 2) {
        this.start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.end_date = moment().subtract(1, "days").format("YYYY-MM-DD");
      } else if (date.id === 3) {
        this.start_date = moment().subtract(29, "days").format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 4) {
        this.start_date = moment().startOf("month").format("YYYY-MM-DD");
        this.end_date = moment().endOf("month").format("YYYY-MM-DD");
      }
    },
    /**
     * This function is resposible for
     * return member on mouse over
     */
    mouseOverUserInfo(id) {
      let member = this.$store.state.custom.get_team_members_list.find(
        (data) => data.id == id
      );
      member = member ? member : null;
      return member;
    },
    /**
     * This function is resposible for
     * get member when id is passed
     */
    userData(id) {
      let member = this.$store.state.custom.get_team_members_list.find(
        (data) => data.id == id
      );
      member = member ? member.image : null;
      return member;
    },
    /**
     * This function is resposible for
     * get deparment list (api call)
     */
    get_department_list() {
      let data = {
        company_id: this.$route.params.id,
      };
      this.$store
        .dispatch("custom/getDeaprtmentList", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          this.departmentList = _.orderBy(
            response.data.department,
            ["name"],
            ["asc"]
          );
          this.$store.commit("custom/set_departments", this.departmentList);
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          if (error.response) {
            if (error.response.data.message === "Unauthenticated.") {
              this.$store.commit("custom/resetState");
              this.$store.dispatch("auth/reset").then(() => {
                return this.$router.push({ name: "login" });
              });
            }
          }
        });
    },
    /**
     * This function is resposible for
     * export to csv
     */
    exportToCSV(tableId) {
      var rows = document.querySelectorAll("table#" + tableId + " tr");
      var csv = [];
      for (var i = 0; i < rows.length; i++) {
        var row = [],
          cols = rows[i].querySelectorAll("td, th");
        for (var j = 0; j < cols.length; j++) {
          var data = cols[j].innerText
            .replace(/(\r\n|\n|\r)/gm, "")
            .replace(/(\s\s)/gm, " ");
          data = data.replace(/"/g, '""');
          row.push('"' + data + '"');
        }
        csv.push(row.join(";"));
      }
      var csv_string = csv.join("\n");
      // Download it
      var filename = "Stafftimerapp timesheet.csv";
      var link = document.createElement("a");
      link.style.display = "none";
      link.setAttribute("target", "_blank");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
      );
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    /**
     * This function is resposible for
     * get export to excel
     */
    // exportToExcel(tblId) {
    //   this.exportImage = false;
    //   setTimeout(() => {
    //     //creating a temporary HTML link element (they support setting file names)
    //     var a = document.createElement("a");
    //     //getting data from our div that contains the HTML table
    //     var data_type = "data:application/vnd.ms-excel;charset=utf-8";
    //     var table_html = $("#timesheetTable")[0].outerHTML;
    //     //    table_html = table_html.replace(/ /g, '%20');
    //     table_html = table_html.replace(/<tfoot[\s\S.]*tfoot>/gim, "");
    //     var css_html =
    //       "<style>td {border: 0.5pt solid #c0c0c0} .tRight { text-align:right} .tLeft { text-align:left} </style>";
    //     //    css_html = css_html.replace(/ /g, '%20');
    //     a.href =
    //       data_type +
    //       "," +
    //       encodeURIComponent(
    //         "<html><head>" +
    //           css_html +
    //           "</" +
    //           "head><body>" +
    //           table_html +
    //           "</body></html>"
    //       );
    //     //setting the file name
    //     a.download = "Stafftimerapp Timesheet.xls";
    //     //triggering the function
    //     a.click();
    //   }, 2000);
    //   //just in case, prevent default behaviour
    //   setTimeout(() => {
    //     this.exportImage = true;
    //   }, 2000);
    // },
    exportToExcel(tableId) {
      for (let index = 0; index < this.timesheetData.length; index++) {
        let element = document.getElementById("span-"+index);
        let parentElement = element.parentElement;
        parentElement.removeChild(element);
      }
      TableToExcel.convert(document.getElementById(tableId), {
        name: "Stafftimerapp Timesheet.xls",
      });
    },
    /**
     * This function is resposible for
     * sorting of data
     */
    sortData(col = "designation") {
      let data = this.timesheetData;
      if (col == "hours") {
        var a = [];
        for (let i = 0; i < data.length; i++) {
          let x = this.getTotalTime(data[i].hours);
          let [hours, minutes, seconds] = x.split(':');
          let totalSeconds = this.convertToSeconds(hours, minutes, seconds)
          data[i].totalhour = x;
          data[i].totalSeconds = totalSeconds;
          a.push(x);
        }
        // col = "totalhour";
        col = "totalSeconds";
        this.selectedOrder = this.selectedOrder == "desc" ? "asc" : "desc";
        this.timesheetData = _.orderBy(data, [col], [this.selectedOrder]);
      }
      else if (col == "percentage") {
        var b = [];
        for (let i = 0; i < data.length; i++) {
          let y = this.getTotalPercentage(data[i].percentage);
          data[i].totalpercentage = y;
          b.push(y);
        }
        col = "totalpercentage";
        this.selectedOrder = this.selectedOrder == "desc" ? "asc" : "desc";
        this.timesheetData = _.orderBy(data, [col], [this.selectedOrder]);
      }
      else {
        this.selectedOrder = this.selectedOrder == "desc" ? "asc" : "desc";
        this.timesheetData = _.orderBy(data, [col], [this.selectedOrder]);
      }
    },
    convertToSeconds(hours, minutes, seconds) {
      return (Number(hours) * 60 * 60) + (Number(minutes) * 60);
    },
    toggleSpan(j, i, total = null) {
      let t = total == "total" ? "total-" : "";
      if (this.timesheetType == "time") {
        this.hoveredCell = t + "time" + j + i;
      }
      if (this.timesheetType == "progress") {
        this.hoveredCell = t + "progress" + j + i;
      }
    },
    toggleSpanGrand(j, grand) {
      let t = grand == "grand" ? "grand-" : "";
      if (this.timesheetType == "time") {
        this.hoveredCell = t + "time" + j;
      }
      if (this.timesheetType == "progress") {
        this.hoveredCell = t + "progress" + j;
      }
    },
    toggleSpanGrandTotal(title, j) {
      if (this.timesheetType == "time") {
        this.hoveredCell = title + "time" + j;
      }
      if (this.timesheetType == "progress") {
        this.hoveredCell = title + "progress" + j;
      }
    },
    toggleSpanGrouped(j, grand) {
      let t = grand == "grouped" ? "grouped-" : "";
      if (this.timesheetType == "time") {
        this.hoveredCell = t + "time" + j;
      }
      if (this.timesheetType == "progress") {
        this.hoveredCell = t + "progress" + j;
      }
    },
    leaveSpan() {
      this.hoveredCell = "";
    },
    /**
     * This function is resposible for
     * fetch time sheet data (api call)
     */
    getTimesheet() {
      let selectGroupBy = this.sheetGroupBy;
      let member;
      if (this.selectedMember == "" || this.selectedMember == null) {
        member = "all";
      } else {
        member = this.selectedMember;
      }
      let data = {
        start_date: this.start_date,
        end_date: this.end_date,
        member_id: member,
        company_id: this.$route.params.id,
        department_id: this.department,
        project_id: this.selectedProject,
      };
      this.$store.commit("custom/toggle_loader", true);
      this.$store
        .dispatch("custom/time_sheet", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          // this.originalData = response.data.data;
          // this.timesheetData = response.data.data;
          let data = response.data;
          let filterData = _.chain(data.data)
            .groupBy("user_hash_id")
            .map((value, key) => ({ k: key, data: value }))
            .value();

          let newData = this.calculateProjectMemberData(data, filterData);
          this.originalData = newData;
          this.timesheetData = newData;

          this.dates = data.dates;
          this.grandTotal = data.grand_total;
          this.toggleLoader = true;
          this.sheetGroupBy = selectGroupBy;
          if (this.sheetGroupBy == "designation") {
            this.timesheetData = _.orderBy(
              this.originalData,
              ["designation"],
              ["asc"]
            );
          }
          if (this.sheetGroupBy == "department") {
            this.timesheetData = _.orderBy(
              this.originalData,
              ["department"],
              ["asc"]
            );
            this.handleGroupBy("department");
          }
          if (this.sheetGroupBy == "leads") {
            this.timesheetData = _.orderBy(
              this.originalData,
              ["name"],
              ["asc"]
            );
            this.handleGroupBy("leader_id");
          }
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          this.toggleLoader = true;
        });
    },
    /**
     * This function is resposible for
     * calculate project time for member
     */
    calculateProjectMemberData(timesheetGetData, filterData) {
      let makeData = [];
      filterData.map((timeData) => {
        if (timeData.data.length > 1) {
          let userInfo;
          let calculateHours = [];
          let calculatedPercentage = [];
          // let real_persontage = 0;
          const d = timeData.data.map((dataTime) => {
            let hour = JSON.parse(dataTime.hours);
            timesheetGetData.dates.map((date) => {
              let hour = JSON.parse(dataTime.hours);
              let percentage = JSON.parse(dataTime.percentage);
              let getHour = hour[date];
              let getpercentage = percentage[date];
              if (getpercentage) {
                let pl = 0;
                pl = calculatedPercentage.find(
                  (hourData) => Object.keys(hourData) == date
                );
                if (pl) {
                  let daily_percentage = pl[date];
                  // real_persontage = getpercentage + daily_percentage;
                  // let sumPer = real_persontage;
                  // let sumPer = getpercentage + real_persontage;
                  let sumPer = getpercentage + daily_percentage;
                  // real_persontage = sumPer;
                  if(sumPer > 0) {
                    // sumPer = Math.round(sumPer / (timeData.data.length));
                  }
                  // console.log(real_persontage, getpercentage, daily_percentage, sumPer, Math.round(sumPer / (timeData.data.length)));
                  calculatedPercentage = calculatedPercentage.filter(
                    (hourData) => Object.keys(hourData) != date
                  );
                  let perArrayData = {
                    [date]: Math.round(sumPer / (timeData.data.length))
                  };
                  calculatedPercentage.push(perArrayData);
                } else {
                  // real_persontage = getpercentage;
                  let perArrayData = {
                    [date]: getpercentage,
                  };
                  calculatedPercentage.push(perArrayData);
                }
              }
              if (getHour) {
                let dl = 0;
                dl = calculateHours.find(
                  (hourData) => Object.keys(hourData) == date
                );
                if (dl) {
                  let minutes = dl[date];
                  let sumMinutes =
                    parseInt(this.convertHours2Minutes(getHour)) +
                    parseInt(minutes);
                  calculateHours = calculateHours.filter(
                    (hourData) => Object.keys(hourData) != date
                  );
                  let arrayData = {
                    [date]: sumMinutes,
                  };
                  calculateHours.push(arrayData);
                } else {
                  let arrayData = {
                    [date]: this.convertHours2Minutes(getHour),
                  };
                  calculateHours.push(arrayData);
                }
              }
            });
            return (userInfo = {
              department: dataTime.department,
              mouse_keystrokes_clicks: dataTime.mouse_keystrokes_clicks,
              designation: dataTime.designation,
              leader_id: dataTime.leader_id,
              name: dataTime.name,
              project_id: dataTime.project_id,
              role_type: dataTime.role_type,
              user_hash_id: dataTime.user_hash_id,
            });
          });
          let a = Object.keys(calculateHours).length;
          let myarr = {};
          let percentage = {};
          // let new_percent = {'as':0};
          // new_percent = JSON.stringify(calculatedPercentage[0]);
          let temp_json = {'as':0};
          if (typeof(calculatedPercentage[0]) != "undefined") {
            const d = calculatedPercentage.map((single, key) => {
              for (const property in single) {
                // console.log(`${property}: ${single[property]}`);
                temp_json[property] = single[property];
              }
            })
            // new_percent = calculatedPercentage;
            // new_percent = JSON.stringify(calculatedPercentage);
            // new_percent = JSON.stringify(Object.assign({},calculatedPercentage));
          }
          for (let i = 0; i < a; i++) {
            for (const [key, value] of Object.entries(calculateHours[i])) {
              let d = this.convertMinutes2Hours(value);
              this.$set(myarr, key, d);
                this.$set(percentage, key, "0");
            }
          }
          userInfo["hours"] = JSON.stringify(myarr);
          // userInfo["percentage"] = JSON.stringify(new_percent);
          userInfo["percentage"] = JSON.stringify(temp_json);
          // userInfo["percentage"] = JSON.stringify(percentage);
          makeData.push(userInfo);
        } else {
          makeData.push(timeData.data[0]);
        }
      });
      return makeData;
    },
    /**
     * This function is resposible for
     * conversion of minutes to hour
     */
    convertMinutes2Hours(num) {
      var hours = Math.floor(num / 60);
      var minutes = num % 60;
      return hours + ":" + minutes;
    },
    /**
     * This function is resposible for
     * conversion of hours to minutes
     */
    convertHours2Minutes(timeInHour) {
      var timeParts = timeInHour.split(":");
      return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    },
    handleGroupBy(by) {
      if (by == "leader_id") {
        this.timesheetData.map((timesheet) => {
          let leader_exist;
          if (timesheet.leader_id) {
            leader_exist = this.timesheetData.find(
              (d) => d.user_hash_id == timesheet.leader_id
            );
            if (!leader_exist) {
              timesheet.leader_id = null;
            }
          }
        });
      }

      let grouped = _.chain(this.timesheetData)
        .groupBy(by)
        .map((value, key) => ({ k: key, data: value }))
        .value();

      let temp = [];
      Object.values(grouped).map((v) => {
        let percent = 0,
          length = 0,
          leader = {},
          cT = "0:0",
          time = "0:0";

        let title = v.k == "null" ? "Miscellaneous" : "Department - " + v.k;
        if (by == "leader_id") {
          leader = this.timesheetData.find((d) => d.user_hash_id == v.k);
          title = leader ? "Team - " + leader["name"] : "Miscellaneous";
        } else {
          leader = null;
        }

        let ob = {
          title: title,
          grouped_row: true,
          grand_total_percentage: "",
          grand_total_time: "",
          hours: "",
          percentage: "",
        };

        temp.push(ob);
        if (by == "leader_id" && leader !== undefined) {
          temp.push(leader);
        }

        let total_hours = this.calculatedDepartmentTotalTime(v.data, leader);
        let total_percantage = this.calculatedDepartmentPercentage(
          v.data,
          leader
        );
        ob.hours = total_hours;
        ob.percentage = total_percantage;

        v.data.map((d) => {
          length = length + 1;
          percent = percent + parseFloat(this.getTotalPercentage(d.percentage));
          cT = this.getTotalTime(d.hours);
          time = this.makeGroupedTotalTime(cT, time);
          temp.push(d);
        });
        if (by == "leader_id" && leader !== undefined) {
          cT = this.getTotalTime(leader.hours);
          time = this.makeGroupedTotalTime(cT, time);
        }

        //MAKE GRAND TOTALS
        ob.grand_total_percentage = Math.round(
          parseFloat((percent / (length * 100)) * 100)
        );
        ob.grand_total_time = time;
      });
      this.timesheetData = temp;
    },
    /**
     * This function is resposible for
     * calculation of total time of deparment
     */
    calculatedDepartmentTotalTime(data, leader) {
      let hours = [];
      this.dates.map((date) => {
        let totalMinutes = 0;
        Object.values(data).map((data) => {
          let minutes = moment
            .duration(JSON.parse(data.hours)[date])
            .asMinutes();
          totalMinutes = totalMinutes + minutes;
        });
        if (leader != null) {
          let leaderMinuts = moment
            .duration(JSON.parse(leader.hours)[date])
            .asMinutes();
          totalMinutes = totalMinutes + leaderMinuts;
        }
        let ob = {
          [date]: totalMinutes
            ? this.convertMinsToHrsMins(totalMinutes)
            : "00:00",
        };
        hours.push(ob);
      });
      return hours;
    },
    /**
     * This function is resposible for
     * conversion of hours to min
     */
    convertMinsToHrsMins(minutes) {
      var h = Math.floor(minutes / 60);
      var m = minutes % 60;
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      return h + ":" + m;
    },
    /**
     * This function is resposible for
     * calculation of department in percent
     */
    calculatedDepartmentPercentage(data, leader) {
      let calcPerentage = [];
      this.dates.map((date) => {
        let totalPercentage = 0,
          count = 0;
        Object.values(data).map((data) => {
          if (JSON.parse(data.percentage)[date] != null || undefined) {
            count++;
            let perentage = JSON.parse(data.percentage)[date];
            totalPercentage = totalPercentage + perentage;
          }
        });
        if (leader != null) {
          let leaderPercentage = JSON.parse(leader.percentage)[date];
          totalPercentage = totalPercentage + leaderPercentage;
          count++;
        }
        totalPercentage = Math.round(totalPercentage / count);
        let ob = {
          [date]: totalPercentage ? totalPercentage : 0,
        };
        calcPerentage.push(ob);
      });
      return calcPerentage;
    },
    /**
     * This function is resposible for
     * calculation of grouped total time
     */
    makeGroupedTotalTime(time, previuosTime) {
      time = time.split(":");
      let hours = 0,
        minutes = 0;
      let prev = previuosTime.split(":");

      hours = parseInt(time[0]) + parseInt(prev[0]);
      minutes = parseInt(time[1]) + parseInt(prev[1]);

      hours = Math.trunc(hours + minutes / 60);
      minutes = minutes % 60;

      return `${hours}:${minutes}`;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("ddd");
      }
    },
    trimData(text) {
      if (!text) {
        return "";
      }
      return text.length > 20 ? text.slice(0, 20) + "..." : text;
    },
    timeStringToFloat: function (time) {
      if (!time) {
        return false;
      }
      let hoursMinutes = time.split(/[.:]/);
      let hours = parseInt(hoursMinutes[0], 10);
      let minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
      return hours + minutes / 60;
    },
    getColorCode(data, d) {
      let parsed = JSON.parse(data)[d];
      let hours = parsed === undefined ? null : parsed;

      if (!hours) {
        return "4";
      }

      let time = this.timeStringToFloat(hours.toString());
      if (time >= 8) {
        return "1";
      }
      if (time >= 7.5 && time <= 8) {
        return "2";
      }
      if (time >= 7 && time <= 7.5) {
        return "3";
      }
      if (time <= 6) {
        return "4";
      }
      if (time >= 6 && time <= 7) {
        return "5";
      }
    },
    getColorCodeProgress: function (t, d) {
      let time = JSON.parse(t)[d];
      if (time >= 80) {
        return "1";
      }
      if (time >= 75 && time <= 80) {
        return "2";
      }
      if (time >= 70 && time <= 75) {
        return "3";
      }
      if (time >= 60 && time <= 70) {
        return "5";
      }
      if (time <= 60 || time === undefined) {
        return "4";
      }
    },
    getTotalTime(time) {
      let data = JSON.parse(time);
      let hours = 0,
        minutes = 0;
      Object.values(data).forEach((v) => {
        if (v) {
          let time = v.split(":");
          hours = hours + parseInt(time[0]);
          minutes = minutes + parseInt(time[1]);
        }
      });
      hours = Math.trunc(hours + minutes / 60);
      minutes = minutes % 60;
      if (hours.toString().length < 2 || minutes.toString().length < 2) {
        if (minutes.toString().length < 2 && hours.toString().length < 2) {
          return `0${hours}:0${minutes}`;
        }
        if (minutes.toString().length < 2 && !hours.toString().length < 2) {
          return `${hours}:0${minutes}`;
        }
        if (!minutes.toString().length < 2 && hours.toString().length < 2) {
          return `0${hours}:${minutes}`;
        }
      }
      return `${hours}:${minutes}`;
    },
    getTotalPercentage(percentage) {
      let data = JSON.parse(percentage);
      let total = 0;
      Object.values(data).forEach((v) => {
        if (v) {
          total = total + parseInt(v);
        }
      });
      return Math.round(total / Object.values(data).length);
    },
    getGrandTotal(type, date) {
      let data = this.grandTotal.find((d) => d.date == date);
      if (data) {
        return type == "time"
          ? data["total_time"]
          : data["total_percentage"] + "%";
      }
      return type == "time" ? "0:0" : "0%";
    },
    /**
     * This function is resposible for
     * trigger report modal and pass data
     */
    getReportData(cell, date, memberId, name, hours) {
      hours = JSON.parse(hours)[date];
      if(hours) {
        this.$store.commit("custom/set_diary_member_id", memberId);
        this.$store.commit("custom/set_work_diary_date_range", date);
        this.$store.commit("custom/set_work_diary_date", date);
        let routeData = this.$router.resolve({
          name: "WorkDiary",
          params: { id: this.$route.params.id },
        });
        window.open(routeData.href, "_blank");

      }
      return;
      let el = document.querySelector(".selected-cell");
      if (el) {
        el.classList.remove("selected-cell");
      }
      document.getElementById(cell).classList.add("selected-cell");
      this.checkReport = true;
      this.dataForReport.search_member = memberId;
      this.dataForReport.search_date = date;
      this.dataForReport.search_name = name;
      this.open = true;
      this.$root.$emit("reset_video");
      this.reportsModal = true;
    },
    playVideo() {
      this.open = false;
      this.reportsModal = false;
      let data = {
        start_date: this.dataForReport.search_date,
        end_date: this.dataForReport.search_date,
        member_id: this.dataForReport.search_member,
        company_id: this.$route.params.id,
      };

      if (JSON.stringify(this.videoData) !== JSON.stringify(data))
        this.videoData = data;
      this.$root.$emit("open_video_modal");
    },
    /**
     * This function is resposible for
     * move user from time sheet to work
     * diary page
     */
    workDairy() {
      let route = this.$router.resolve({
        path:
          "work-diary" +
          "/" +
          this.dataForReport.search_member +
          "/" +
          this.dataForReport.search_date,
      });
      window.open(route.href, "_blank");
    },
    /**
     * This function is resposible for
     * trigger intelligence modal and update data
     */
    intellegentReport() {
      this.open = false;
      this.dataForIntlReport.search_member = this.dataForReport.search_member;
      this.dataForIntlReport.search_date = this.dataForReport.search_date;
      this.dataForIntlReport.search_name = this.dataForReport.search_name;
      this.checkIntlReport = true;
      this.open = true;
      this.intellegenceRModal = true;
    },
    report(date, member_id, name) {
      this.checkReport = true;
      this.dataForReport.search_member = member_id;
      this.dataForReport.search_date = date;
      this.dataForReport.search_name = name;
      this.open = true;
      this.reportsModal = true;
    },
    /**
     * This function is resposible for
     * close reports modal and clear data
     */
    closeModalReport() {
      this.reportsModal = false;
      this.open = false;
      this.checkReport = false;
      this.reportsModal = false;
    },
    /**
     * This function is resposible for
     * close intelligence modal and clear data
     */
    closeModalIntelligenceReport() {
      this.open = false;
      this.intellegenceRModal = false;
      this.checkIntlReport = false;
    },
  },
};
</script>
<style scoped>
.active-custom-btn {
  background-color: #2758f6 !important;
  color: #fff !important;
}

@media (max-width: 657px) {
  .my-class {
    top: 270px !important;
    left: 60px !important;
  }
}
.my-class {
  transform-origin: left top !important;
  z-index: 8 !important;
}
.display-flex {
  display: flex !important;
}
.w-200 {
  width: 150px !important;
}
.labels {
  color: #36454f;
  font-family: "Poppins", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.date-picker-icon {
  margin-left: 150px;
}
.calender-btn {
  width: 200px !important;
  z-index: 0.9 !important;
  align-content: right !important;
  margin-left: -0px;
}
.selected-date {
  border: 1px solid #e9f1fe !important;
  margin-top: -11px !important;
  font-size: 12px !important;
  display: flex !important;
  height: 40px !important;
  padding: 9px !important;
  border-radius: 3px;
  background-color: #fff;
}
.datepicker {
  width: 200px !important;
  height: 37px !important;
  border: 2px solid #e9f1fe;
}
.search_btn {
  border-radius: 1px !important;
  background-color: #2758f6 !important;
  color: white;
  font-weight: 600;
  font-size: 16px;
  margin-top: 27px;
  border-color: #2758f6 !important;
  -webkit-box-shadow: 0 0 0 0.2rem #2758f6 !important;
  box-shadow: 0 0 0 0.2rem #2758f6 !important;
  width: 190px;
  height: 33px;
  cursor: pointer;
}
/******clender-icon-setting********/
.calender-limited-area {
  position: relative;
}
.clender-icon-timesheet {
  position: absolute !important;
  margin-left: 0px !important;
  width: 100% !important;
  right: 0px !important;
}
.slected-date-width {
  width: 76% !important;
}
/******clender-icon-setting********/
@media (min-width: 280px) and (max-width: 680px) {
  .report {
    width: 100%;
    margin-left: 3px;
  }
}

@media (min-width: 300px) and (max-width: 820px) {
  .timesheet-table tbody tr td:nth-child(1),
  .timesheet-table tbody tr td:nth-of-type(2),
  .timesheet-table tbody tr td:nth-of-type(3),
  .timesheet-table tbody tr td:nth-of-type(4) {
    position: relative;
    left: 0px;
    margin-top: -400px !important;
  }
  .timesheet-table thead th {
    position: relative;
    left: 0px !important;
  }
}
@media (min-width: 300px) and (max-width: 820px) {
  .custom-col-width {
    margin-top: -195px !important;
  }
}
.svg-btn {
  height: 40px;
  border: 0.75px solid #e9f1fe;
  border-radius: 3px;
  background-color: white;
  -webkit-box-shadow: 0 2px 4px 0 rgba(165, 165, 165, 0.23);
  box-shadow: 0 2px 4px 0 rgba(165, 165, 165, 0.23);
  color: #36454f !important;
  margin-bottom: 0rem !important;
  font-size: 14px;
  width: 160px;
  text-align: left;
}

button#dropdownMenuButton {
  margin-bottom: 15px !important;
  margin-top: 1.5rem !important;
}
</style> 
<style type="text/css" > 
.custom-padding {
  height: 19%;
}
table.table-div tbody tr {
  background: transparent;
  border: 0.5px solid #000000;
  padding: 1rem;
}

.table-div {
  width: 100%;
  height: calc(100vh + 100px);
  overflow-x: scroll;
  overflow-y: visible;
  position: relative;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  font: normal 13px Arial, sans-serif;
}

.table-div tbody td {
  border: 0.5px solid #000000;
  color: #333;
  padding: 10px;
  white-space: nowrap;
  padding: 1rem;
  cursor: pointer;
}

.zui-wrapper {
  position: relative;
  height: calc(100vh + 100px);
  width: 100%;
}

.zui-scroller {
  margin-left: 32.3rem;
  overflow-x: scroll;
  overflow-y: visible;
  padding-bottom: 5px;
}

.table-div .zui-sticky-col {
  border: 0.5px solid #000000;
  left: 0;
  position: absolute;
  top: auto;
  width: 120px;
}

th.text-center.scroll-column.custom-column-color {
  background-color: #ff7043;
}

.table-div .table-sticky-col-name {
  left: 0;
  width: 209px;
}

.table-div .table-sticky-col-designation {
  width: 199px;
}
.table-div .table-sticky-col-totaltime {
  width: 111px;
}

.table-div .table-sticky-col-totaltimeRow {
  width: 518px !important;
  left: 0px;
  border-right: 1px solid #cbdaf2 !important;
}
.status-1 {
  background: #22a522;
}

.status-2 {
  background: #7abd7a;
}

.status-3 {
  background: #b5e0b5;
}

.status-4 {
  background: #ff7043;
}

.status-5 {
  background: #e3f1dd;
}
table.table-div .total-row,
.total-col {
  background: #cbdaf2 !important;
}

table.table-div .total-row > td,
.total-col {
  font-weight: bold !important;
  font-size: 18px;
  color: blue !important;
  padding: 0.8rem !important;
}

.timesheet-table {
  position: relative;
  border-collapse: collapse;
}

.timesheet-table thead th {
  background-color: #2758f6;
  border: none;
  color: #fff;
  text-align: left;
  white-space: nowrap;
  padding: 1rem;
  font-size: 16px;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
}

.timesheet-table thead th:nth-child(1) {
  left: 0;
  z-index: 1;
}

.timesheet-table thead th:nth-of-type(2) {
  left: 156px;
  z-index: 1;
}

.timesheet-table thead th:nth-of-type(3) {
  left: 277px;
  z-index: 1;
}

.timesheet-table thead th:nth-of-type(4) {
  left: 400px;
  z-index: 1;
}
.timesheet-table thead th:nth-of-type(5) {
  left: 542px;
  z-index: 1;
}

.timesheet-table tbody th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  background: #fff;
  border-right: 1px solid #ccc;
}

.timesheet-table tbody tr td:nth-child(1) {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  background: #f1f1f1;
  border-right: 1px solid black;
}

.timesheet-table tbody tr td:nth-of-type(2) {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 155px;
  background: #f1f1f1;
  border-right: 1px solid black;
}

.timesheet-table tbody tr td:nth-of-type(3) {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 275px;
  background: #f1f1f1;
  border-right: 1px solid black;
}

.timesheet-table tbody tr td:nth-of-type(4) {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 398px;
  border-right: 1px solid black;
}
.timesheet-table tbody tr td:nth-of-type(5) {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 539px;
  border-right: 1px solid black;
}

.extra-td-1 {
  background-color: #cbdaf2 !important;
  border-right: 1px solid #cbdaf2 !important;
}

.extra-td-2 {
  background-color: #cbdaf2 !important;
}
div#log_data_modal {
  width: 100%;
  margin: auto;
  margin-top: 4%;
}

.intellegence-report {
  width: 130%;
  margin-left: -13%;
  margin-top: 5%;
}

div#report_modal {
  margin-top: 4%;
}

.report {
  width: 120%;
  margin-left: -13%;
  margin-top: 7%;
}
.btn-main {
  background-color: #2758f6;
  border: 1px solid #2758f6;
  padding: 2px;
  margin-right: 5px;
}
.table-div .table-sticky-col-first-row {
  width: 518px;
  left: 0px;
  border-right: 1px solid #cbdaf2 !important;
}

/* .icons-span {
      margin-left: 50%;
    } */
.selected-cell {
  background-color: #c872c8;
  border: 2px solid honeydew !important;
}
.create-button {
  float: right;
  margin-left: 100px;
  height: 42px;
  width: 140px;
  border-radius: 3px !important;
  background-color: #2758f6;
  color: white;
  font-weight: 600;
  font-size: 20px;
  margin-top: -14px;
  border: unset;
  border: none;
}
.create-button:hover {
  background-color: #2758f6;
  color: white;
  border: none;
}
.create-button:active {
  background-color: #2758f6;
  color: white;
}
.grouped-row {
  background-color: #2658f6 !important;
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
}
.designation-label {
  display: inline-block;
  line-height: 1;
  font-size: 9px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  border: 2px solid #29b6f6;
  color: #29b6f6;
  margin-left: 18px;
  padding: 4px 8px;
}

/*DATE RANGE CSS*/

.calender-icon img {
  height: 14px;
  width: 30px;
  margin-top: 10px;
  float: right;
}

.label {
  font-family: "Poppins", "sans-serif";
  font-size: 14px;
  line-height: 18px;
  margin-left: 1px;
  font-weight: 600;
  color: #ff6b6b;
}

.daterange {
  border: 1px solid #e9f1fe;
  border-radius: 3px !important;
  text-align: center;
  width: 100% !important;
}

.daterange:hover {
  border: 1px solid #2758f6 !important;
}

.labels {
  color: #36454f;
  font-family: "Poppins", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
}
.error-img {
  width: 300px;
  height: 250px;
  margin-top: 0px;
}
.error_text {
  font-size: larger;
  font-weight: 500;
  color: #a9bcdb;
  margin-top: 20px;
}

.sort_icon {
  height: 17px;
  margin-left: 6px;
  cursor: pointer;
}
.sort {
  cursor: pointer;
}
.timesheet-image {
  width: 31px;
}
/*END DATE RANGE CSS*/

@media (max-width: 1700px) and (min-width: 1600px) {
  button#dropdownMenuButton {
    margin-left: 1rem;
  }
}
button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color !important;
}
.mdi-dark.mdi-inactive:before {
  color: #2758f6 !important;
}
#custom-list-style-new .v-list-item__title {
  font-size: 12px !important;
}

/* #app .v-menu__content {
      min-width: 200px !important; */
/* left: 290px !important; */
/* top: 300px !important
    } */
#dashboard-calender span.v-btn__content i {
  /* width: 300px !important; */
}
#apply-btn {
  background-color: #2758f6 !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 900 !important;
  font-family: "Poppins" !important;
}
@media (max-width: 1700px) and (min-width: 1600px) {
  /* #app .v-menu__content {
          min-width: 230px!important; */
  /* left: 1230px!important; */
  /* top: 300px!important;
        } */
  #dashboard-calender span.v-btn__content i {
    height: 36px;
    /* width: 332px !important; */
  }
}

@media screen and (min-width: 1264px) and (max-width: 1350px) {
  .create-button-timesheet {
    margin-right: 0rem !important;
    width: 150px !important;
  }
  .calender-limited-area button#dashboard-calender {
    position: absolute !important;
    right: 0px !important;
    width: 137% !important;
    margin-left: 0px !important;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1263px) {
  .calender-limited-area button#dashboard-calender {
    position: absolute !important;
    right: 0px !important;
    width: 100% !important;
    margin-left: 0px !important;
  }
  .create-button-timesheet {
    margin-right: 0rem !important;
    width: 150px !important;
  }
}
/* @media (max-width:1366px){
    .width-spacing .slected-date-width {
        width: 69% !important;
    }
    .width-spacing .clender-icon-timesheet {
        right: 33px !important;
    }
    } */
@media (max-width: 1200px) {
  .width-spacing .clender-icon-timesheet i {
    margin-left: 0px !important;
    right: 37px;
    position: absolute;
  }
  .timeshee-header .col-md-2 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .create-button-timesheet {
    width: 100%;
    margin-left: 0px !important;
    margin-top: 0px;
  }
  .width-spacing .slected-date-width {
    width: 91% !important;
  }
  .timeshee-header {
    margin: 0px !important;
  }
  /* .v-menu__content {
          top: 434px !important;
          left: 69px !important;
        } */
}
@media (max-width: 980) {
  /* .v-menu__content {
          left: 59px !important;
        } */
}
@media (max-width: 767px) {
  /* .v-menu__content {
          top: 434px !important;
          left: 46px !important;
        } */
}
@media (max-width: 655px) {
  /* .v-menu__content {
          top: 682px !important;
          left: 42px !important;
        } */
}
@media (max-width: 601px) {
  /* .v-menu__content {
          top: 855px !important;
          left: 38px !important;
        } */
  .timeshee-header .col-md-2 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .calender-limited-area {
    position: relative;
    z-index: 99;
  }
}
@media (max-width: 480px) {
  .width-spacing .clender-icon-timesheet i {
    right: 6px;
  }
  /* .v-menu__content {
          top: 867px !important;
          left: 21px !important;
        } */
}
</style>
